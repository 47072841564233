/*#region paddingTop*/

.padding-top-10 {
    padding-top: 10px;
}

.padding-top-15 {
    padding-top: 15px;
}

.padding-top-20 {
    padding-top: 20px;
}

.padding-top-25 {
    padding-top: 25px;
}

.padding-top-35 {
    padding-top: 35px;
}

.padding-top-40 {
    padding-top: 40px;
}

.padding-top-50 {
    padding-top: 50px;
}

.padding-top-60 {
    padding-top: 60px;
}

.padding-top-80 {
    padding-top: 80px;
}

.padding-top-100 {
    padding-top: 100px;
}

/*#endregion paddingTop*/

/*#region paddingBottom*/
.padding-bottom-10 {
    padding-bottom: 10px;
}

.padding-bottom-15 {
    padding-bottom: 15px;
}

.padding-bottom-20 {
    padding-bottom: 20px;
}

.padding-bottom-25 {
    padding-bottom: 25px;
}

.padding-bottom-30 {
    padding-bottom: 30px;
}

.padding-bottom-35 {
    padding-bottom: 35px;
}

.padding-bottom-40 {
    padding-bottom: 40px;
}

.padding-bottom-50 {
    padding-bottom: 50px;
}

.padding-bottom-60 {
    padding-bottom: 60px;
}

.padding-bottom-80 {
    padding-bottom: 80px;
}

.padding-bottom-100 {
    padding-bottom: 100px;
}
/*#endregion paddingBottom*/

/*#region paddingY*/

.padding-y-10 {
    padding-top: 10px;
    padding-bottom: 10px;
}

.padding-y-15 {
    padding-top: 15px;
    padding-bottom: 15px;
}

.padding-y-20 {
    padding-top: 20px;
    padding-bottom: 20px;
}

.padding-y-25 {
    padding-top: 25px;
    padding-bottom: 25px;
}

.padding-y-30 {
	padding-top: 30px;
	padding-bottom: 30px;
}

.padding-y-35 {
    padding-top: 35px;
    padding-bottom: 35px;
}

.padding-y-40 {
    padding-top: 40px;
    padding-bottom: 40px;
}

.padding-y-50 {
    padding-top: 50px;
    padding-bottom: 50px;
}

.padding-y-60 {
    padding-top: 60px;
    padding-bottom: 60px;
}

.padding-y-80 {
    padding-top: 80px;
    padding-bottom: 80px;
}

.padding-y-100 {
    padding-top: 100px;
    padding-bottom: 100px;
}

/*#endregion paddingY*/

/*#region paddingX*/

.padding-x-10p {
    padding: 0 10%;
}

.padding-x-10 {
    padding: 0 10px;
}

.padding-x-15 {
    padding: 0 15px;
}

.padding-x-20 {
    padding: 0 20px;
}



/*#endregion paddingX*/

/*#region marginTop*/

.margin-top-0 {
    margin-top: 0;
}

.margin-top-10 {
    margin-top: 10px;
}

.margin-top-15 {
    margin-top: 15px;
}

.margin-top-20 {
    margin-top: 20px;
}


.margin-top-25 {
    margin-top: 25px;
}

.margin-top-30 {
    margin-top: 30px;
}

.margin-top-35 {
    margin-top: 35px;
}

.margin-top-40 {
    margin-top: 40px;
}

.margin-top-50 {
    margin-top: 50px;
}

.margin-top-60 {
    margin-top: 60px;
}

.margin-top-80 {
    margin-top: 80px;
}

.margin-top-100 {
    margin-top: 100px;
}

/*#endregion marginTop*/

/*#region marginBottom*/
.margin-bottom-10 {
    margin-bottom: 10px;
}

.margin-bottom-15 {
    margin-bottom: 15px;
}

.margin-bottom-20 {
    margin-bottom: 20px;
}

.margin-bottom-25 {
    margin-bottom: 25px;
}
.margin-bottom-30 {
    margin-bottom: 30px;
}

.margin-bottom-35 {
    margin-bottom: 35px;
}

.margin-bottom-40 {
    margin-bottom: 40px;
}

.margin-bottom-50 {
    margin-bottom: 50px;
}

.margin-bottom-60 {
    margin-bottom: 60px;
}

.margin-bottom-80 {
    margin-bottom: 80px;
}

.margin-bottom-100 {
    margin-bottom: 100px;
}
/*#endregion marginBottom*/

/*#region marginY*/

.margin-y-10 {
    margin-top: 10px;
    margin-bottom: 10px;
}

.margin-y-15 {
    margin-top: 15px;
    margin-bottom: 15px;
}

.margin-y-20 {
    margin-top: 20px;
    margin-bottom: 20px;
}

.margin-y-25 {
    margin-top: 25px;
    margin-bottom: 25px;
}

.margin-y-30 {
    margin-top: 30px;
    margin-bottom: 30px;
}


.margin-y-35 {
    margin-top: 35px;
    margin-bottom: 35px;
}

.margin-y-40 {
    margin-top: 40px;
    margin-bottom: 40px;
}

.margin-y-50 {
    margin-top: 50px;
    margin-bottom: 50px;
}

.margin-y-60 {
    margin-top: 60px;
    margin-bottom: 60px;
}

.margin-y-80 {
    margin-top: 80px;
    margin-bottom: 80px;
}

.margin-y-100 {
    margin-top: 100px;
    margin-bottom: 100px;
}

/*#endregion marginY*/

.white {
    color: #FFF;
}

.black {
    color: #000;
}

.container-bg-white {
    background-color: #fff;
}

.uppercase {
    text-transform: uppercase;
}

.relative {
    position: relative;
}

.display-inline-block {
    display: inline-block;
}

.display-block {
    display: block;
}

img.HoverBounce,
.HoverBounce img {
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    -webkit-transition: -webkit-transform 0.5s ease-in-out;
    transition: -webkit-transform 0.5s ease-in-out;
    transition: transform 0.5s ease-in-out;
    transition: transform 0.5s ease-in-out, -webkit-transform 0.5s ease-in-out;
    display: block;
    overflow: hidden;
}

    img.HoverBounce :hover,
    .HoverBounce img :hover {
        -webkit-transform: scale(1.1);
                transform: scale(1.1);
        -webkit-transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
                transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
        z-index: 100;
    }

a.HoverBounce {
    display: block;
}

    a.HoverBounce img {
        -webkit-transform: translateZ(0);
                transform: translateZ(0);
        -webkit-backface-visibility: hidden;
                backface-visibility: hidden;
        -webkit-transition: -webkit-transform 0.5s ease-in-out;
        transition: -webkit-transform 0.5s ease-in-out;
        transition: transform 0.5s ease-in-out;
        transition: transform 0.5s ease-in-out, -webkit-transform 0.5s ease-in-out;
        display: block;
        overflow: hidden;
    }

    a.HoverBounce :hover {
        -webkit-transform: scale(1.1);
                transform: scale(1.1);
        -webkit-transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
                transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
        z-index: 100;
    }

/*#region fonts*/
.OpenSans,
.BodyFont {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
}
.OpenSansSemiBold {
  font-family: 'Open Sans', sans-serif;
  font-weight: 600 !important;
}
.OpenSansBold {
  font-family: 'Open Sans', sans-serif;
  font-weight: 700 !important;
}
.Montserrat {
  font-family: 'Montserrat', sans-serif;
  font-weight: 400 !important;
}
.MontserratMedium {
  font-family: 'Montserrat', sans-serif;
  font-weight: 500 !important;
}
.MontserratSemiBold {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600 !important;
}
.MontserratBold {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700 !important;
}
.SairaCondensedMedium {
  font-family: 'Saira Condensed', sans-serif;
  font-weight: 500 !important;
}
html {
  font-size: 16px;
}
body {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
}
.pageContent.textContent,
.lower-content {
  color: #222;
  font-size: 1rem;
  padding-top: 0;
}
@media (max-width: 400px) {
  .pageContent.textContent a,
  .lower-content a {
    word-break: break-word;
  }
}
@media (max-width: 767px) {
  .pageContent.textContent,
  .lower-content {
    text-align: center;
  }
}
.pageContent.textContent h1,
.lower-content h1,
.pageContent.textContent h2,
.lower-content h2 {
  margin-top: 0;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700 !important;
  font-size: 2.4rem;
  text-transform: uppercase;
  margin-bottom: 20px;
}
.pageContent.textContent p,
.lower-content p {
  margin-bottom: 20px;
}
.pageContent.textContent a,
.lower-content a {
  position: relative;
  padding: 0 5px;
  margin: 0 1px;
  display: inline-block;
  color: #222;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600 !important;
}
.pageContent.textContent a:hover,
.lower-content a:hover,
.pageContent.textContent a:focus,
.lower-content a:focus {
  color: #fff;
}
.pageContent.textContent a:hover:before,
.lower-content a:hover:before,
.pageContent.textContent a:focus:before,
.lower-content a:focus:before {
  -webkit-transform: scaleY(1);
          transform: scaleY(1);
}
.pageContent.textContent a:before,
.lower-content a:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #de0588;
  background-image: -webkit-linear-gradient(left, #de0588, #ff7e00);
  background: -webkit-gradient(linear, left top, right top, from(#de0588), to(#ff7e00));
  background: linear-gradient(to right, #de0588, #ff7e00);
  -webkit-transition: -webkit-transform 0.3s;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
  z-index: -1;
  -webkit-transform-origin: center bottom;
          transform-origin: center bottom;
  -webkit-transform: scaleY(0.1);
          transform: scaleY(0.1);
}
.lower-content {
  background: #fff;
  margin-top: 30px;
  border-radius: 5px;
  padding: 20px 20px 10px !important;
}
.section-title {
  margin-top: 0 !important;
  margin-bottom: 20px !important;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700 !important;
  font-size: 3rem;
  text-align: center;
  text-transform: uppercase;
}
/*#endregion fonts*/
form {
  position: relative;
  max-width: 100vw;
}
.outer-wrapper {
  max-width: 100vw;
  overflow-x: hidden;
  position: relative;
  min-height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.outer-wrapper--alt .main-content {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}
.outer-wrapper--home .main-content__row {
  padding-top: 20px;
}
.container {
  position: relative;
  width: 100%;
}
@media (min-width: 1330px) {
  .container {
    width: 1300px;
  }
}
/*#region topLinksMobile*/
#TopLinksMobile {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: #000;
  color: white;
  z-index: 20;
  padding: 0;
  border-bottom: 1px solid white;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
}
#TopLinksMobile .navbar {
  margin-bottom: 0;
}
@media (min-width: 768px) {
  #TopLinksMobile {
    display: none !important;
  }
}
#TopLinksMobile #NavigationToggleText {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 14px 63px;
  text-align: right;
}
#TopLinksMobile .navbar-toggle .icon-bar {
  background: white;
}
#TopLinksMobile .row {
  padding-top: 10px;
}
#TopLinksMobile .input-group {
  margin-bottom: 0;
}
#MobileHomeLink {
  position: fixed;
  top: 10px;
  z-index: 10;
  background: transparent none repeat scroll 0 0;
  color: white;
  left: 10px;
  border-radius: 50%;
  border: 2px solid white;
}
/*#endregion topLinksMobile*/
.ticker {
  padding: 20px 0;
  background-color: #de0588;
  background-image: -webkit-linear-gradient(left, #de0588, #ff7e00);
  background: -webkit-gradient(linear, left top, right top, from(#de0588), to(#ff7e00));
  background: linear-gradient(to right, #de0588, #ff7e00);
  position: relative;
}
.ticker:before,
.ticker:after {
  z-index: 1;
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-position: center center;
  background-repeat: no-repeat;
  background-color: #de0588;
  background-image: -webkit-linear-gradient(left, #de0588, #ff7e00);
  background: -webkit-gradient(linear, left top, right top, from(#de0588), to(#ff7e00));
  background: linear-gradient(to right, #de0588, #ff7e00);
  pointer-events: none;
}
.ticker:before {
  -webkit-mask-image: linear-gradient(90deg, #000 5px, rgba(0, 0, 0, 0) 125px, rgba(0, 0, 0, 0) 100%);
          mask-image: linear-gradient(90deg, #000 5px, rgba(0, 0, 0, 0) 125px, rgba(0, 0, 0, 0) 100%);
}
.ticker:after {
  -webkit-mask-image: linear-gradient(270deg, #000 5px, rgba(0, 0, 0, 0) 125px, rgba(0, 0, 0, 0) 100%);
          mask-image: linear-gradient(270deg, #000 5px, rgba(0, 0, 0, 0) 125px, rgba(0, 0, 0, 0) 100%);
}
@media (max-width: 767px) {
  .ticker {
    padding-top: 75px;
  }
}
.ticker__inner {
  position: relative;
  height: 24px;
}
@media (max-width: 767px) {
  .ticker__inner {
    height: 18px;
  }
}
.ticker__text {
  position: absolute;
  left: 0;
  bottom: 0;
  -webkit-transform: translateX(100vw);
          transform: translateX(100vw);
  text-transform: uppercase;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700 !important;
  color: #fff;
  font-size: 1.5rem;
  white-space: nowrap;
  -webkit-animation: ticker 20s infinite linear;
          animation: ticker 20s infinite linear;
  line-height: 1;
}
@media (max-width: 767px) {
  .ticker__text {
    font-size: 1.125rem;
    -webkit-animation: ticker 10s infinite linear;
            animation: ticker 10s infinite linear;
  }
}
.ticker__text p {
  margin-bottom: 0;
  white-space: nowrap;
}
@-webkit-keyframes ticker {
  to {
    -webkit-transform: translateX(-105%);
            transform: translateX(-105%);
  }
}
@keyframes ticker {
  to {
    -webkit-transform: translateX(-105%);
            transform: translateX(-105%);
  }
}
.header {
  background: #000;
  padding: 30px 0;
}
.header__inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
@media (min-width: 992px) {
  .header__inner {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
}
.header__logo-panel {
  text-align: center;
}
@media (min-width: 992px) {
  .header__logo-panel {
    text-align: left;
  }
}
.header__info-panel {
  text-align: center;
}
.header__logo {
  display: inline-block;
  max-width: 320px;
  margin-bottom: 20px;
}
@media (min-width: 992px) {
  .header__logo {
    margin-bottom: 0;
  }
}
.header__phone {
  display: block;
  margin-bottom: 10px;
  color: #fff;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600 !important;
  font-size: 3.1875rem;
  line-height: 1;
}
.header__phone:hover {
  color: #fff;
}
@media (max-width: 767px) {
  .header__phone {
    font-size: 2.25rem;
  }
}
.header__email {
  display: block;
  margin-bottom: 10px;
  color: #fff;
  font-size: 1.8rem;
  font-family: 'Saira Condensed', sans-serif;
  font-weight: 500 !important;
  text-transform: uppercase;
}
.header__email:hover {
  color: #fff;
}
@media (max-width: 767px) {
  .header__email {
    font-size: 1.5rem;
  }
}
.header__social-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
}
@media (max-width: 767px) {
  .header__social-row {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin-top: 20px;
  }
}
.header__social-links {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0 3px;
}
@media (max-width: 767px) {
  .header__social-links {
    margin-bottom: 10px;
  }
}
.header__social-link {
  margin: 0 3px;
}
.header__social-link img {
  width: 45px;
  height: 45px;
}
.header__badge {
  margin: 0 3px;
}
.header__badge--biha {
  width: 46px;
}
.header__badge--tipe {
  width: 48px;
}
.header__badge--bcn {
  width: 69px;
  height: 45px;
}
.toplinks {
  background: #000;
  border-top: 3px solid #2fbeef;
}
.toplinks__container {
  padding-left: 0;
  padding-right: 0;
}
.toplinks__ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-bottom: 0;
  padding-left: 0;
}
.toplinks__li {
  position: relative;
  list-style: none;
  text-align: center;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}
.toplinks__li .dropdown-toggle:focus {
  text-decoration: none;
}
.toplinks__link {
  position: relative;
  display: block;
  padding: 15px 5px;
  color: #fff;
  font-size: 1.25rem;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400 !important;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
}
.toplinks__link:hover,
.toplinks__link.active,
.toplinks__link:focus {
  color: #fff;
  background: #2fbeef;
}
.toplinks__link.active {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600 !important;
}
.toplinks__dropdown {
  padding: 10px;
  margin: 0;
  border: none;
  -webkit-box-shadow: none;
          box-shadow: none;
  text-align: center;
  background-color: #000 !important;
  -webkit-box-shadow: 0 0 3px rgba(0, 0, 0, 0.25);
          box-shadow: 0 0 3px rgba(0, 0, 0, 0.25);
}
.toplinks__dropdown-li {
  display: block;
}
.toplinks__dropdown-link {
  position: relative;
  padding: 5px 10px !important;
  font-size: 1.2rem;
  color: #fff !important;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400 !important;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
}
.toplinks__dropdown-link:hover,
.toplinks__dropdown-link:focus,
.toplinks__dropdown-link.active {
  background-color: #2fbeef !important;
  color: #fff !important;
}
.toplinks__dropdown-link.active {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600 !important;
}
.banner #CarouselContainer {
  padding-top: 38.93%;
  overflow: hidden;
}
.banner #CarouselContainer .carousel-inner {
  position: absolute;
  top: 0;
  height: 100%;
}
.banner #CarouselContainer .carousel-inner .item {
  width: 100%;
}
.banner #CarouselContainer .carousel-inner .item a img,
.banner #CarouselContainer .carousel-inner .item img {
  width: 100%;
}
.book-online {
  background-color: #de0588;
  background-image: -webkit-linear-gradient(left, #de0588, #ff7e00);
  background: -webkit-gradient(linear, left top, right top, from(#de0588), to(#ff7e00));
  background: linear-gradient(to right, #de0588, #ff7e00);
  padding: 35px 0;
}
.book-online__inner {
  position: relative;
}
@media (min-width: 768px) {
  .book-online__inner {
    height: 45px;
  }
}
.book-online__title-wrapper {
  overflow: hidden;
  margin-bottom: 30px;
}
.book-online__title {
  font-size: 2.5rem;
  margin-bottom: 0 !important;
}
@media (min-width: 992px) {
  .book-online__title {
    text-align: left;
  }
}
@media (max-width: 767px) {
  .book-online__title {
    font-size: 2.25rem;
  }
}
.book-online__title .char {
  visibility: hidden;
}
.book-online__title.animated .char {
  visibility: visible;
  -webkit-animation: fadeInUp2 0.75s 1 alternate cubic-bezier(0.18, 0.89, 0.32, 1.28) both;
          animation: fadeInUp2 0.75s 1 alternate cubic-bezier(0.18, 0.89, 0.32, 1.28) both;
  -webkit-animation-delay: calc(30ms * var(--char-index));
          animation-delay: calc(30ms * var(--char-index));
}
.book-online #PostcodeSearchLeft select,
.book-online #PostcodeSearchLeft input,
.book-online .SearchDate,
.book-online .SearchCategory,
.book-online .SearchButton {
  height: 45px;
  border-radius: 5px;
  background-repeat: no-repeat;
  border: none;
  width: 24.73%;
  padding-left: 15px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600 !important;
  font-size: 1rem;
  color: #3d3d3d;
}
.book-online #PostcodeSearchLeft select::-webkit-input-placeholder,
.book-online #PostcodeSearchLeft input::-webkit-input-placeholder,
.book-online .SearchDate::-webkit-input-placeholder,
.book-online .SearchCategory::-webkit-input-placeholder,
.book-online .SearchButton::-webkit-input-placeholder {
  opacity: 1;
}
.book-online #PostcodeSearchLeft select::-moz-placeholder,
.book-online #PostcodeSearchLeft input::-moz-placeholder,
.book-online .SearchDate::-moz-placeholder,
.book-online .SearchCategory::-moz-placeholder,
.book-online .SearchButton::-moz-placeholder {
  opacity: 1;
}
.book-online #PostcodeSearchLeft select::-ms-input-placeholder,
.book-online #PostcodeSearchLeft input::-ms-input-placeholder,
.book-online .SearchDate::-ms-input-placeholder,
.book-online .SearchCategory::-ms-input-placeholder,
.book-online .SearchButton::-ms-input-placeholder {
  opacity: 1;
}
.book-online #PostcodeSearchLeft select::placeholder,
.book-online #PostcodeSearchLeft input::placeholder,
.book-online .SearchDate::placeholder,
.book-online .SearchCategory::placeholder,
.book-online .SearchButton::placeholder {
  opacity: 1;
}
@media (min-width: 768px) {
  .book-online #PostcodeSearchLeft select,
  .book-online #PostcodeSearchLeft input,
  .book-online .SearchDate,
  .book-online .SearchCategory,
  .book-online .SearchButton {
    position: absolute;
  }
}
@media (max-width: 767px) {
  .book-online #PostcodeSearchLeft select,
  .book-online #PostcodeSearchLeft input,
  .book-online .SearchDate,
  .book-online .SearchCategory,
  .book-online .SearchButton {
    display: block;
    width: 100%;
    max-width: 350px;
    margin: 0 auto 15px;
  }
}
.book-online #PostcodeSearchLeft select {
  background-image: url("https://files.bookingonline.co.uk/image/upload/f_auto/themes/040/icon-area.png");
  background-size: 15px 21px;
  background-position: right 26px center;
}
@media (min-width: 768px) {
  .book-online #PostcodeSearchLeft select {
    left: 0;
  }
}
.book-online .SearchDate {
  background-image: url("https://files.bookingonline.co.uk/image/upload/f_auto/themes/040/icon-date.png");
  background-size: 23px 25px;
  background-position: right 10px center;
}
@media (min-width: 768px) {
  .book-online .SearchDate {
    left: 27.83%;
  }
}
.book-online .SearchCategory {
  background-image: url("https://files.bookingonline.co.uk/image/upload/f_auto/themes/040/icon-category.png");
  background-size: 22px 21px;
  background-position: right 24px center;
}
@media (min-width: 768px) {
  .book-online .SearchCategory {
    left: 55.21%;
  }
}
.book-online .SearchButton {
  background: #000;
  border-radius: 0;
  color: #fff;
  padding-left: 0;
  text-transform: uppercase;
  font-family: 'Saira Condensed', sans-serif;
  font-weight: 500 !important;
  font-size: 1.35rem;
  display: -moz-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: -webkit-box;
  display: flex;
  -moz-justify-content: center;
  -ms-justify-content: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -moz-align-items: center;
  -ms-align-items: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border: 2px solid #000;
  -webkit-transition: color 0.3s, background 0.3s;
  transition: color 0.3s, background 0.3s;
}
.book-online .SearchButton:hover {
  color: #000;
  background: transparent;
}
@media (max-width: 767px) {
  .book-online .SearchButton {
    margin-top: 20px;
  }
}
@media (min-width: 768px) {
  .book-online .SearchButton {
    width: 16.78%;
    right: 0;
  }
}
.featured-categories__row {
  margin-top: 30px;
}
@media (max-width: 400px) {
  .featured-categories__col {
    width: 100%;
  }
}
.featured-categories__col:nth-child(4n + 1) .featured-categories__link {
  border-bottom: 10px solid #2e9dd2;
}
.featured-categories__col:nth-child(4n + 1) .featured-categories__link:hover .featured-categories__title,
.featured-categories__col:nth-child(4n + 1) .featured-categories__link:focus .featured-categories__title {
  background-color: #2e9dd2;
}
.featured-categories__col:nth-child(4n + 2) .featured-categories__link {
  border-bottom: 10px solid #a9d37c;
}
.featured-categories__col:nth-child(4n + 2) .featured-categories__link:hover .featured-categories__title,
.featured-categories__col:nth-child(4n + 2) .featured-categories__link:focus .featured-categories__title {
  background-color: #a9d37c;
}
.featured-categories__col:nth-child(4n + 3) .featured-categories__link {
  border-bottom: 10px solid #f7e843;
}
.featured-categories__col:nth-child(4n + 3) .featured-categories__link:hover .featured-categories__title,
.featured-categories__col:nth-child(4n + 3) .featured-categories__link:focus .featured-categories__title {
  background-color: #f7e843;
}
.featured-categories__col:nth-child(4n + 4) .featured-categories__link {
  border-bottom: 10px solid #f0544c;
}
.featured-categories__col:nth-child(4n + 4) .featured-categories__link:hover .featured-categories__title,
.featured-categories__col:nth-child(4n + 4) .featured-categories__link:focus .featured-categories__title {
  background-color: #f0544c;
}
.featured-categories__link {
  text-align: center;
  display: block;
  margin-bottom: 30px;
  -webkit-transition: border 0.3s;
  transition: border 0.3s;
}
.featured-categories__link:hover,
.featured-categories__link:focus {
  border-bottom: 10px solid #fff !important;
}
.featured-categories__link:hover .featured-categories__title,
.featured-categories__link:focus .featured-categories__title {
  color: #fff;
}
.featured-categories__image-panel {
  position: relative;
  padding-top: 100%;
}
.featured-categories__image {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.featured-categories__title {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700 !important;
  color: #000;
  font-size: 1.2rem;
  padding: 15px 0;
  display: -moz-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: -webkit-box;
  display: flex;
  -moz-justify-content: center;
  -ms-justify-content: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -moz-align-items: center;
  -ms-align-items: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-transition: color 0.3s, background-color 0.3s;
  transition: color 0.3s, background-color 0.3s;
}
.main-content__left-col {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  overflow: hidden;
}
.main-content__row {
  padding: 40px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.left-col__logo-panel {
  margin-top: auto;
}
.left-col__phone {
  margin-top: 10px;
  color: #f0544c;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600 !important;
  display: block;
  text-align: center;
  font-size: 2rem;
}
.category-links {
  margin-bottom: 30px;
}
.category-links__link,
.category-links__child-link {
  position: relative;
  display: block;
  margin-bottom: 15px;
  padding: 10px 15px;
  text-align: left;
  font-size: 1.3rem;
  color: #000;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600 !important;
  border-radius: 10px;
  -webkit-transition: margin 0.3s;
  transition: margin 0.3s;
}
.category-links__link:before,
.category-links__child-link:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  border-left: 8px solid #fff;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  opacity: 0;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
}
.category-links__link:hover,
.category-links__child-link:hover,
.category-links__link:focus,
.category-links__child-link:focus,
.category-links__link.active,
.category-links__child-link.active {
  color: #000;
}
.category-links__link:hover:before,
.category-links__child-link:hover:before,
.category-links__link:focus:before,
.category-links__child-link:focus:before,
.category-links__link.active:before,
.category-links__child-link.active:before {
  opacity: 1;
}
.category-links__link:nth-of-type(5n + 1),
.category-links__child-link:nth-of-type(5n + 1) {
  background-color: #8f8ec2;
}
.category-links__link:nth-of-type(5n + 1) + div .category-links__child-link,
.category-links__child-link:nth-of-type(5n + 1) + div .category-links__child-link {
  background-color: #8f8ec2;
}
.category-links__link:nth-of-type(5n + 2),
.category-links__child-link:nth-of-type(5n + 2) {
  background-color: #279dd3;
}
.category-links__link:nth-of-type(5n + 2) + div .category-links__child-link,
.category-links__child-link:nth-of-type(5n + 2) + div .category-links__child-link {
  background-color: #279dd3;
}
.category-links__link:nth-of-type(5n + 3),
.category-links__child-link:nth-of-type(5n + 3) {
  background-color: #a8ce7d;
}
.category-links__link:nth-of-type(5n + 3) + div .category-links__child-link,
.category-links__child-link:nth-of-type(5n + 3) + div .category-links__child-link {
  background-color: #a8ce7d;
}
.category-links__link:nth-of-type(5n + 4),
.category-links__child-link:nth-of-type(5n + 4) {
  background-color: #f4e845;
}
.category-links__link:nth-of-type(5n + 4) + div .category-links__child-link,
.category-links__child-link:nth-of-type(5n + 4) + div .category-links__child-link {
  background-color: #f4e845;
}
.category-links__link:nth-of-type(5n + 5),
.category-links__child-link:nth-of-type(5n + 5) {
  background: #ea544d;
}
.category-links__link:nth-of-type(5n + 5) + div .category-links__child-link,
.category-links__child-link:nth-of-type(5n + 5) + div .category-links__child-link {
  background: #ea544d;
}
.category-links__child-link {
  margin-left: 10px;
}
.home-video-row {
  padding-bottom: 20px;
}
.home-video-row__video {
  padding-bottom: 56.25%;
  overflow: hidden;
  position: relative;
}
.home-video-row__video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.home-video-row__social-feed {
  position: relative;
  overflow: hidden;
  padding-bottom: 118%;
}
.home-video-row__social-feed #FacebookEmbedPanel {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.page-listings__wrapper {
  background: #000;
  padding: 70px 0 60px;
}
.page-listings__title {
  color: #fff;
  margin-bottom: 50px !important;
}
@media (max-width: 767px) {
  .page-listings__title {
    font-size: 2.75rem;
  }
}
.page-listings__title .word {
  visibility: hidden;
}
.page-listings__title.animated .word {
  visibility: visible;
}
.page-listings__title.animated .word:first-child {
  -webkit-animation: fadeInLeft 1.2s 1 ease-out both;
          animation: fadeInLeft 1.2s 1 ease-out both;
}
.page-listings__title.animated .word:last-child {
  -webkit-animation: fadeInRight 1.2s 0.1s 1 ease-out both;
          animation: fadeInRight 1.2s 0.1s 1 ease-out both;
}
@media (max-width: 420px) {
  .page-listings > div {
    width: 100%;
  }
}
.castlePanel {
  width: 100%;
  margin: 0 0 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  border: none;
  overflow: hidden;
  padding: 10px 10px;
  border-radius: 5px;
  -webkit-box-shadow: 0 3px 5px rgba(0, 0, 0, 0.3);
          box-shadow: 0 3px 5px rgba(0, 0, 0, 0.3);
  -webkit-transition: -webkit-box-shadow 0.3s;
  transition: -webkit-box-shadow 0.3s;
  transition: box-shadow 0.3s;
  transition: box-shadow 0.3s, -webkit-box-shadow 0.3s;
}
.castlePanel:hover {
  -webkit-box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
          box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
}
.castlePanel .SingleImagePanel {
  height: auto;
  background-color: transparent;
  padding-top: 0;
}
.castlePanel .SingleImagePanel:before {
  content: "";
  display: block;
  padding-top: 100%;
  width: 100%;
}
.castlePanel .castleTitlePanel {
  padding: 0 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 10px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.castlePanel .castleTitlePanel a {
  font-size: 1rem;
  color: #444;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600 !important;
}
.castlePanel .castlePriceDefault,
.castlePanel .castlePriceDiscounted {
  padding: 0 5px;
  margin-top: 10px;
  font-size: 1.125rem;
  font-family: 'Open Sans', sans-serif;
  font-weight: 700 !important;
  color: #000;
}
.castlePanel .castlePriceDefault span.oldprice,
.castlePanel .castlePriceDiscounted span.oldprice {
  font-size: 0.8rem;
}
.castlePanel .castlePriceDefault span.newprice,
.castlePanel .castlePriceDiscounted span.newprice {
  font-size: 1rem;
}
.castlePanel .castleCheckBook {
  height: auto;
  -webkit-box-ordinal-group: 5;
      -ms-flex-order: 4;
          order: 4;
  padding: 10px 5px;
  margin-top: 10px;
  text-transform: uppercase;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700 !important;
  color: #fff;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.5);
  background-color: #de0588;
  background: -webkit-gradient(linear, left top, left bottom, from(#de0588), to(#e82763));
  background: linear-gradient(to bottom, #de0588, #e82763);
  border-radius: 5px;
  font-size: 1rem;
}
.DetailsTitle h1,
.DetailsTitle h2,
.DetailsMainImage {
  background-color: #de0588;
  font-size: 2rem;
}
.DetailsTitle h1,
.DetailsTitle h2 {
  padding: 10px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500 !important;
}
@media (max-width: 767px) {
  a.BackToCategory {
    -webkit-transform: translateY(30px);
            transform: translateY(30px);
    margin-bottom: 0;
  }
}
.clients {
  padding: 65px 0 95px;
}
.clients__title {
  color: #000;
  position: relative;
}
@media (max-width: 767px) {
  .clients__title {
    font-size: 2.5rem;
  }
}
.clients__title:after {
  content: "";
  background: #bfbfbf;
  width: 27px;
  height: 5px;
  position: absolute;
  bottom: -30px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.clients .slick-track {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.clients__inner {
  margin-top: 60px;
}
.clients__image-panel {
  display: -moz-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: -webkit-box;
  display: flex;
  -moz-justify-content: center;
  -ms-justify-content: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -moz-align-items: center;
  -ms-align-items: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: flex !important;
}
.testimonials {
  padding: 70px 0;
  background-color: #de0588;
  background-image: -webkit-linear-gradient(left, #de0588, #ff7e00);
  background: -webkit-gradient(linear, left top, right top, from(#de0588), to(#ff7e00));
  background: linear-gradient(to right, #de0588, #ff7e00);
  position: relative;
  overflow: hidden;
}
.testimonials__bg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  opacity: 0.1;
  mix-blend-mode: multiply;
  -webkit-animation-name: pulse;
          animation-name: pulse;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  -webkit-animation-duration: 30s;
          animation-duration: 30s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
}
.testimonials__inner {
  background: #fff;
  margin: 0 auto;
  max-width: 800px;
  padding: 50px 50px 40px;
  border-radius: 5px;
  position: relative;
}
@media (max-width: 767px) {
  .testimonials__inner {
    padding: 25px;
  }
}
.testimonials__title {
  margin-bottom: 50px !important;
}
@media (max-width: 767px) {
  .testimonials__title {
    font-size: 2.5rem;
  }
}
.testimonials .RotatorTestimonialContent {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
      -ms-flex-direction: column-reverse;
          flex-direction: column-reverse;
  text-align: center;
}
.testimonials .RotatorTestimonialContent p {
  margin-bottom: 0;
}
.testimonials time {
  display: none;
}
.testimonials a[itemprop="author"] {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700 !important;
  font-size: 1.625rem;
  color: #d900b1;
  margin-bottom: 0;
  margin-top: 20px;
  display: block;
  position: relative;
  -webkit-animation-delay: 0.45s;
          animation-delay: 0.45s;
  visibility: hidden;
}
@media (max-width: 767px) {
  .testimonials a[itemprop="author"] {
    font-size: 1.25rem;
  }
}
.testimonials p[itemprop="reviewBody"] {
  font-size: 1.375rem;
  visibility: hidden;
  display: block;
  position: relative;
}
@media (max-width: 767px) {
  .testimonials p[itemprop="reviewBody"] {
    font-size: 1.125rem;
  }
}
.testimonials .carousel-inner .item {
  -webkit-transition-property: opacity;
  transition-property: opacity;
}
.testimonials .carousel-inner .item,
.testimonials .carousel-inner .active.left,
.testimonials .carousel-inner .active.right {
  opacity: 0;
}
.testimonials .carousel-inner .active,
.testimonials .carousel-inner .next.left,
.testimonials .carousel-inner .prev.right {
  opacity: 1;
}
.testimonials .carousel-inner .next,
.testimonials .carousel-inner .prev,
.testimonials .carousel-inner .active.left,
.testimonials .carousel-inner .active.right {
  left: 0;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
.testimonials .item.active p[itemprop="reviewBody"],
.testimonials .item.active a[itemprop="author"] {
  -webkit-animation-name: fadeInUp;
          animation-name: fadeInUp;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  visibility: visible;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
  -webkit-animation-direction: normal;
          animation-direction: normal;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
}
.testimonials #RotatorTestimonialsHeader {
  margin-bottom: 15px;
}
.testimonials #RotatorTestimonialsHeader p,
.testimonials #RotatorTestimonialsHeader a {
  text-align: center;
  color: #000;
  font-size: 1rem;
  position: relative;
}
.testimonials .RotatorTestimonialRating {
  max-width: 280px;
  margin: 20px auto 0;
}
.newsletter {
  padding: 70px 0 50px;
  background-color: #de0588;
  background-image: -webkit-linear-gradient(left, #de0588, #ff7e00);
  background: -webkit-gradient(linear, left top, right top, from(#de0588), to(#ff7e00));
  background: linear-gradient(to right, #de0588, #ff7e00);
}
.newsletter__intro {
  color: #fff;
  text-align: center;
  margin-bottom: 30px;
}
.newsletter__intro h2 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700 !important;
  font-size: 3rem;
  margin-top: 0;
  text-transform: uppercase;
}
@media (max-width: 767px) {
  .newsletter__intro h2 {
    font-size: 2.25rem;
  }
}
.newsletter__intro p {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600 !important;
  font-size: 1.75rem;
  margin-top: 10px;
}
@media (max-width: 767px) {
  .newsletter__intro p {
    font-size: 1.25rem;
  }
}
.newsletter label {
  display: none;
}
.newsletter #NewsletterControl {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin: 0 auto;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
@media (min-width: 992px) {
  .newsletter #NewsletterControl {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }
}
.newsletter__name,
.newsletter__email {
  height: 45px;
  border: none;
  border-radius: 5px;
  padding-left: 15px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600 !important;
  font-size: 1rem;
  color: #000;
  margin-bottom: 15px;
  display: block;
}
.newsletter__name::-webkit-input-placeholder,
.newsletter__email::-webkit-input-placeholder {
  color: #000;
  opacity: 1;
}
.newsletter__name::-moz-placeholder,
.newsletter__email::-moz-placeholder {
  color: #000;
  opacity: 1;
}
.newsletter__name::-ms-input-placeholder,
.newsletter__email::-ms-input-placeholder {
  color: #000;
  opacity: 1;
}
.newsletter__name::placeholder,
.newsletter__email::placeholder {
  color: #000;
  opacity: 1;
}
@media (min-width: 992px) {
  .newsletter__name,
  .newsletter__email {
    margin-bottom: 0;
  }
}
.newsletter__name {
  width: 100%;
  max-width: 300px;
}
@media (min-width: 992px) {
  .newsletter__name {
    width: 300px;
    max-width: none;
  }
}
.newsletter__email {
  width: 100%;
  max-width: 300px;
}
@media (min-width: 992px) {
  .newsletter__email {
    width: 350px;
    max-width: none;
    margin: 0 10px;
  }
}
.newsletter__button {
  height: 45px;
  border: none;
  color: #fff;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700 !important;
  font-size: 1.2rem;
  text-transform: uppercase;
  display: -moz-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: -webkit-box;
  display: flex;
  -moz-justify-content: center;
  -ms-justify-content: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -moz-align-items: center;
  -ms-align-items: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: relative;
  width: 160px;
  border-radius: 8px;
  border: 2px solid #078abc;
  -webkit-transition: border 0.3s;
  transition: border 0.3s;
  z-index: 1;
}
.newsletter__button:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-position: center center;
  background-repeat: no-repeat;
  z-index: -1;
  border-radius: 5px;
  background-color: #2db0e2;
  background: -webkit-gradient(linear, left top, left bottom, from(#2db0e2), to(#078abc));
  background: linear-gradient(to bottom, #2db0e2, #078abc);
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
}
.newsletter__button:hover {
  color: #fff;
  border: 2px solid #fff;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.25);
}
.newsletter__button:hover:before {
  opacity: 0;
}
.home-gallery {
  background: #fff;
  display: block;
  position: relative;
  padding: 20px 0 15px;
  background: transparent;
}
.home-gallery:before,
.home-gallery:after {
  content: "";
  position: absolute;
  width: 150px;
  top: 0;
  bottom: 0;
}
@media (max-width: 767px) {
  .home-gallery:before,
  .home-gallery:after {
    width: 50px;
  }
}
.home-gallery:before {
  left: 0;
  z-index: 1;
  background-color: #000000;
  background-image: -webkit-linear-gradient(left, #000000, rgba(0, 0, 0, 0));
  background: -webkit-gradient(linear, left top, right top, from(#000000), to(rgba(0, 0, 0, 0)));
  background: linear-gradient(to right, #000000, rgba(0, 0, 0, 0));
}
.home-gallery:after {
  right: 0;
  -webkit-mask-image: -webkit-gradient(linear, right top, left top, color-stop(20%, #000000), to(rgba(0, 0, 0, 0)));
  -webkit-mask-image: linear-gradient(-90deg, #000000 20%, rgba(0, 0, 0, 0) 100%);
          mask-image: -webkit-gradient(linear, right top, left top, color-stop(20%, #000000), to(rgba(0, 0, 0, 0)));
          mask-image: linear-gradient(-90deg, #000000 20%, rgba(0, 0, 0, 0) 100%);
  background-position-x: calc(((100vw - 1200px)/2) - 100vw + 3px);
  background-color: rgba(0, 0, 0, 0);
  background-image: -webkit-linear-gradient(left, rgba(0, 0, 0, 0), #000000);
  background: -webkit-gradient(linear, left top, right top, from(rgba(0, 0, 0, 0)), to(#000000));
  background: linear-gradient(to right, rgba(0, 0, 0, 0), #000000);
}
.home-gallery__wrapper {
  background-color: #000;
}
.home-gallery__container {
  background: #fff;
}
.home-gallery__slide {
  margin: 0 10px;
}
.home-gallery__link {
  display: block;
  border-radius: 5px;
  overflow: hidden;
  padding: 0;
  margin: 0 10px;
  border: 5px solid #fff !important;
  border-radius: 0 !important;
  background-color: #fff;
  -webkit-box-shadow: 2px 3px 5px 0 rgba(0, 0, 0, 0.3);
          box-shadow: 2px 3px 5px 0 rgba(0, 0, 0, 0.3);
  padding: 1% 1% 63% !important;
}
.home-gallery__link:hover {
  border-color: #ababab;
}
.home-gallery__link img {
  left: 0 !important;
  top: 0 !important;
}
.DetailsLeft {
  background-image: none;
  padding-bottom: 0;
}
.BookButtons {
  text-align: center;
}
.BookButtons .BookButton,
.BookButtons .CheckButton {
  width: 180px;
  margin-left: auto;
  float: none;
  margin: 0 10px 10px;
}
.footer {
  background: #000;
  padding: 30px 0;
  color: #fff;
}
@media (max-width: 767px) {
  .footer {
    text-align: center;
  }
}
.footer a,
.footer p {
  color: #fff;
}
.footer a:hover,
.footer p:hover {
  color: #fff;
}
@media (max-width: 767px) {
  .footer__col {
    text-align: center;
  }
}
@media (min-width: 992px) {
  .footer__flex-row-md {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
}
@media (min-width: 768px) {
  .footer__text-right-sm {
    text-align: right;
  }
}
.footer__links {
  text-align: center;
}
.footer__link {
  margin: 0 5px;
}
.footer #BCNLink {
  display: inline-block;
  margin-top: 15px;
}
@media (min-width: 768px) {
  .footer #BCNLink {
    margin-top: 0;
  }
}
/*#region Animations*/
.wow {
  visibility: hidden;
}
.animated {
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
}
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.fadeIn {
  -webkit-animation-name: fadeIn;
          animation-name: fadeIn;
}
.fadeInUp2 {
  -webkit-animation-name: fadeInUp2;
          animation-name: fadeInUp2;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-direction: alternate;
          animation-direction: alternate;
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
}
@-webkit-keyframes fadeInUp2 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100px, 0);
            transform: translate3d(0, 100px, 0);
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeInUp2 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100px, 0);
            transform: translate3d(0, 100px, 0);
  }
  100% {
    opacity: 1;
  }
}
/* Recommended styles for Splitting */
.splitting .word,
.splitting .char {
  display: inline-block;
}
/* Psuedo-element chars */
.splitting .char {
  position: relative;
}
/**
 * Populate the psuedo elements with the character to allow for expanded effects
 * Set to `display: none` by default; just add `display: block` when you want
 * to use the psuedo elements
 */
.splitting .char::before,
.splitting .char::after {
  content: attr(data-char);
  position: absolute;
  top: 0;
  left: 0;
  visibility: hidden;
  -webkit-transition: inherit;
  transition: inherit;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
/* Expanded CSS Variables */
.splitting {
  /* The center word index */
  --word-center: calc((var(--word-total) - 1) / 2);
  /* The center character index */
  --char-center: calc((var(--char-total) - 1) / 2);
  /* The center character index */
  --line-center: calc((var(--line-total) - 1) / 2);
}
.splitting .word {
  /* Pecent (0-1) of the word's position */
  --word-percent: calc(var(--word-index) / var(--word-total));
  /* Pecent (0-1) of the line's position */
  --line-percent: calc(var(--line-index) / var(--line-total));
}
.splitting .char {
  /* Percent (0-1) of the char's position */
  --char-percent: calc(var(--char-index) / var(--char-total));
  /* Offset from center, positive & negative */
  --char-offset: calc(var(--char-index) - var(--char-center));
  /* Absolute distance from center, only positive */
  --distance: calc((var(--char-offset) * var(--char-offset)) / var(--char-center));
  /* Distance from center where -1 is the far left, 0 is center, 1 is far right */
  --distance-sine: calc(var(--char-offset) / var(--char-center));
  /* Distance from center where 1 is far left/far right, 0 is center */
  --distance-percent: calc(var(--distance) / var(--char-center));
}
/*#endregion Animations*/
@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
  }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
            transform: scale3d(1.05, 1.05, 1.05);
  }
  to {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
  }
}
@keyframes pulse {
  0% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
  }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
            transform: scale3d(1.05, 1.05, 1.05);
  }
  to {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
  }
}
.pulse {
  -webkit-animation-name: pulse;
          animation-name: pulse;
}
