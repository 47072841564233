@import "../Assets/functions.less";
@import (inline) "../Assets/utilities.css";
@xxs: ~"(max-width: 400px)";
@mobile: ~"(max-width: 767px)";
@tablet: ~"(min-width: 768px)";
@desktop: ~"(min-width: 1330px)";
@mobileNavColor: #000;
@castleDetailsColor: #de0588;
@cloudUrl: "https://files.bookingonline.co.uk/image/upload/f_auto/themes/040/";
/*#region fonts*/
.OpenSans, .BodyFont {
	font-family: 'Open Sans', sans-serif;
	font-weight: 400;
}

.OpenSansSemiBold {
	font-family: 'Open Sans', sans-serif;
	font-weight: 600 !important;
}

.OpenSansBold {
	font-family: 'Open Sans', sans-serif;
	font-weight: 700 !important;
}

.Montserrat {
	font-family: 'Montserrat', sans-serif;
	font-weight: 400 !important;
}

.MontserratMedium {
	font-family: 'Montserrat', sans-serif;
	font-weight: 500 !important;
}

.MontserratSemiBold {
	font-family: 'Montserrat', sans-serif;
	font-weight: 600 !important;
}

.MontserratBold {
	font-family: 'Montserrat', sans-serif;
	font-weight: 700 !important;
}

.SairaCondensedMedium {
	font-family: 'Saira Condensed', sans-serif;
	font-weight: 500 !important;
}

html {
	font-size: 16px;
}

body {
	.BodyFont;
}

.nicefont {
}

.pageContent.textContent, .lower-content {
	@color: #222;
	color: @color;
	font-size: 1rem;
	padding-top: 0;

	@media @xxs {
		a {
			// for long links in chrome
			word-break: break-word;
		}
	}

	@media @mobile {
		text-align: center;
	}

	h1,h2 {
		margin-top: 0;
		.MontserratBold;
		font-size: 2.4rem;
		text-transform: uppercase;
		margin-bottom: 20px;
	}

	p {
		margin-bottom: 20px;
	}

	a {
		position: relative;
		padding: 0 5px;
		margin: 0 1px;
		display: inline-block;
		color: @color;
		transition: color 0.3s;
		.OpenSansSemiBold;

		&:hover, &:focus {
			color: #fff;

			&:before {
				transform: scaleY(1);
			}
		}

		&:before {
			content: "";
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 100%;
			.HorizontalGradient(#de0588,#ff7e00);
			transition: transform 0.3s;
			z-index: -1;
			transform-origin: center bottom;
			transform: scaleY(0.1);
		}
	}
}

.lower-content{
	background:#fff;
	margin-top:30px;
	border-radius:5px;
	padding:20px 20px 10px !important;
	
}

.section-title {
	text-align: center;
	margin-top: 0 !important;
	margin-bottom: 20px !important;
	.MontserratBold;
	font-size: 3rem;
	text-align: center;
	text-transform: uppercase;
}

h2, h3, h4 {
}
/*#endregion fonts*/
form {
	position: relative;
	max-width: 100vw;
}

.outer-wrapper {
	max-width: 100vw;
	overflow-x: hidden;
	position: relative;
	min-height: 100vh;
	display: flex;
	flex-direction: column;

	&--alt {
		.main-content {
			flex-grow: 1;
		}
	}

	&--home {
		.main-content {
			&__row {
				padding-top: 20px;
			}
		}
	}
}

.container {
	position: relative;
	width: 100%;

	@media @mobile {
	}

	@media @tablet {
	}

	@media @desktop {
		width: 1300px;
	}
}
/*#region topLinksMobile*/
#TopLinksMobile {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	background: @mobileNavColor;
	color: white;
	z-index: 20;
	padding: 0;
	border-bottom: 1px solid white;
	.BodyFont();

	.navbar {
		margin-bottom: 0;
	}

	@media @tablet {
		display: none !important;
	}

	#NavigationToggleText {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		padding: 14px 63px;
		text-align: right;
	}

	.navbar-toggle .icon-bar {
		background: white;
	}

	.row {
		padding-top: 10px;
	}

	.input-group {
		margin-bottom: 0;
	}
}

#MobileHomeLink {
	position: fixed;
	top: 10px;
	z-index: 10;
	background: transparent none repeat scroll 0 0;
	color: white;
	left: 10px;
	border-radius: 50%;
	border: 2px solid white;
}
/*#endregion topLinksMobile*/
.ticker {
	padding: 20px 0;
	.HorizontalGradient(#de0588,#ff7e00);
	position: relative;

	&:before, &:after {
		z-index: 1;
		.StickToEdges;
		.HorizontalGradient(#de0588,#ff7e00);
		pointer-events: none;
	}

	&:before {
		mask-image: linear-gradient(90deg,#000 5px,rgba(0,0,0,0) 125px,rgba(0,0,0,0) 100%);
	}

	&:after {
		mask-image: linear-gradient(270deg,#000 5px,rgba(0,0,0,0) 125px,rgba(0,0,0,0) 100%);
	}

	@media @mobile {
		padding-top: 75px;
	}

	&__inner {
		position: relative;
		height: 24px;

		@media @mobile {
			height: 18px;
		}
	}

	&__text {
		position: absolute;
		left: 0;
		bottom: 0;
		transform: translateX(100vw);
		text-transform: uppercase;
		.MontserratBold;
		color: #fff;
		font-size: 1.5rem;
		white-space: nowrap;
		animation: ticker 20s infinite linear;
		line-height: 1;

		@media @mobile {
			font-size: 1.125rem;
			animation: ticker 10s infinite linear;
		}

		p {
			margin-bottom: 0;
			white-space: nowrap;
		}
	}
}

@keyframes ticker {
	to {
		transform: translateX(-105%);
	}
}

.header {
	background: #000;
	padding: 30px 0;

	&__inner {
		display: flex;
		flex-direction: column;

		@media @md {
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
		}
	}

	&__logo-panel {
		text-align: center;

		@media @md {
			text-align: left;
		}
	}

	&__info-panel {
		text-align: center;
	}

	&__logo {
		display: inline-block;
		max-width: 320px;
		margin-bottom: 20px;

		@media @md {
			margin-bottom: 0;
		}
	}

	&__phone {
		display: block;
		margin-bottom: 10px;
		color: #fff;
		.MontserratSemiBold;
		font-size: 3.1875rem;
		line-height: 1;

		&:hover {
			color: #fff;
		}

		@media @mobile {
			font-size: 2.25rem;
		}
	}

	&__email {
		display: block;
		margin-bottom: 10px;
		color: #fff;
		font-size: 1.8rem;
		.SairaCondensedMedium;
		text-transform: uppercase;

		&:hover {
			color: #fff;
		}

		@media @mobile {
			font-size: 1.5rem;
		}
	}

	&__social-row {
		display: flex;
		justify-content: center;
		align-items: flex-end;

		@media @mobile {
			flex-direction: column;
			align-items: center;
			margin-top: 20px;
		}
	}

	&__social-links {
		display: flex;
		margin: 0 3px;

		@media @mobile {
			margin-bottom: 10px;
		}
	}

	&__social-link {
		margin: 0 3px;

		img {
			width: 45px;
			height: 45px;
		}
	}

	&__badges {
	}

	&__badge {
		margin: 0 3px;

		&--biha {
			width: 46px;
		}

		&--tipe {
			width: 48px;
		}

		&--bcn {
			width: 69px;
			height: 45px;
		}
	}
}

.toplinks {
	background: #000;
	border-top: 3px solid #2fbeef;

	&__container {
		padding-left: 0;
		padding-right: 0;
	}

	&__ul {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		margin-bottom: 0;
		padding-left: 0;
	}

	&__li {
		position: relative;
		list-style: none;
		text-align: center;
		flex-grow: 1;

		.dropdown-toggle:focus {
			text-decoration: none;
		}
	}

	&__link {
		position: relative;
		display: block;
		padding: 15px 5px;
		color: #fff;
		font-size: 1.25rem;
		.Montserrat;
		transition: background-color 0.3s;

		&:hover, &.active, &:focus {
			color: #fff;
			background: #2fbeef;
		}

		&.active {
			.MontserratSemiBold;
		}
	}

	&__dropdown {
		padding: 10px;
		margin: 0;
		border: none;
		box-shadow: none;
		text-align: center;
		background-color: #000 !important;
		box-shadow: 0 0 3px rgba(0,0,0,0.25);
	}

	&__dropdown-li {
		display: block;
	}

	&__dropdown-link {
		position: relative;
		padding: 5px 10px !important;
		font-size: 1.2rem;
		color: #fff !important;
		.Montserrat;
		transition: background-color 0.3s;

		&:hover, &:focus, &.active {
			background-color: #2fbeef !important;
			color: #fff !important;
		}

		&.active {
			.MontserratSemiBold;
		}
	}
}

.banner {
	.BannerPanelSet(38.93%);
}

.book-online {
	.HorizontalGradient(#de0588,#ff7e00);
	padding: 35px 0;

	&__inner {
		position: relative;

		@media @sm {
			height: 45px;
		}
	}

	&__title-wrapper {
		overflow: hidden;
		margin-bottom: 30px;
	}

	&__title {
		font-size: 2.5rem;
		margin-bottom: 0 !important;

		@media @md {
			text-align: left;
		}

		@media @mobile {
			font-size: 2.25rem;
		}

		.char {
			visibility: hidden;
		}

		&.animated {
			.char {
				visibility: visible;
				animation: fadeInUp2 0.75s 1 alternate cubic-bezier(.18,.89,.32,1.28) both;
				animation-delay: ~'calc(30ms * var(--char-index))';
			}
		}
	}

	#PostcodeSearchLeft select, #PostcodeSearchLeft input, .SearchDate, .SearchCategory, .SearchButton {
		height: 45px;
		border-radius: 5px;
		background-repeat: no-repeat;
		border: none;
		width: 24.73%;
		padding-left: 15px;
		.OpenSansSemiBold;
		font-size: 1rem;
		color: #3d3d3d;

		&::placeholder {
			opacity: 1;
		}

		@media @sm {
			position: absolute;
		}

		@media @mobile {
			display: block;
			width: 100%;
			max-width: 350px;
			margin: 0 auto 15px;
		}
	}

	#PostcodeSearchLeft select {
		background-image: url("@{cloudUrl}icon-area.png");
		background-size: 15px 21px;
		background-position: right 26px center;

		@media @sm {
			left: 0;
		}
	}

	.SearchDate {
		background-image: url("@{cloudUrl}icon-date.png");
		background-size: 23px 25px;
		background-position: right 10px center;

		@media @sm {
			left: 27.83%;
		}
	}

	.SearchCategory {
		background-image: url("@{cloudUrl}icon-category.png");
		background-size: 22px 21px;
		background-position: right 24px center;

		@media @sm {
			left: 55.21%;
		}
	}

	.SearchButton {
		background: #000;
		border-radius: 0;
		color: #fff;
		padding-left: 0;
		text-transform: uppercase;
		.SairaCondensedMedium;
		font-size: 1.35rem;
		.FlexCenter;
		border: 2px solid #000;
		transition: color 0.3s, background 0.3s;

		&:hover {
			color: #000;
			background: transparent;
		}

		@media @mobile {
			margin-top: 20px;
		}

		@media @sm {
			width: 16.78%;
			right: 0;
		}
	}
}

.featured-categories {
	&__row {
		margin-top: 30px;
	}

	&__col {
		@media (max-width:400px) {
			width: 100%;
		}

		&:nth-child(4n + 1) .featured-categories__link {
			border-bottom: 10px solid #2e9dd2;

			&:hover, &:focus {
				.featured-categories__title {
					background-color: #2e9dd2;
				}
			}
		}

		&:nth-child(4n + 2) .featured-categories__link {
			border-bottom: 10px solid #a9d37c;

			&:hover, &:focus {
				.featured-categories__title {
					background-color: #a9d37c;
				}
			}
		}

		&:nth-child(4n + 3) .featured-categories__link {
			border-bottom: 10px solid #f7e843;

			&:hover, &:focus {
				.featured-categories__title {
					background-color: #f7e843;
				}
			}
		}

		&:nth-child(4n + 4) .featured-categories__link {
			border-bottom: 10px solid #f0544c;

			&:hover, &:focus {
				.featured-categories__title {
					background-color: #f0544c;
				}
			}
		}
	}

	&__link {
		text-align: center;
		display: block;
		margin-bottom: 30px;
		transition: border 0.3s;

		&:hover, &:focus {
			border-bottom: 10px solid #fff !important;

			.featured-categories__title {
				color: #fff;
			}
		}
	}

	&__image-panel {
		position: relative;
		padding-top: 100%;
	}

	&__image {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		width: 100%;
		height: 100%;
		object-fit: contain;
	}

	&__title {
		.MontserratBold;
		color: #000;
		font-size: 1.2rem;
		padding: 15px 0;
		.FlexCenter;
		transition: color 0.3s, background-color 0.3s;
	}
}

.main-content {
	&__left-col {
		display: flex;
		flex-direction: column;
		overflow: hidden;
	}

	&__row {
		padding: 40px 0;
		display: flex;
	}
}

.left-col {
	&__logo-panel {
		margin-top: auto;
	}

	&__phone {
		margin-top: 10px;
		color: #f0544c;
		.MontserratSemiBold;
		display: block;
		text-align: center;
		font-size: 2rem;
	}
}

.category-links {
	margin-bottom: 30px;

	&__link, &__child-link {
		position: relative;
		display: block;
		margin-bottom: 15px;
		padding: 10px 15px;
		text-align: left;
		font-size: 1.3rem;
		color: #000;
		.MontserratSemiBold;
		border-radius: 10px;
		transition: margin 0.3s;

		&:before {
			@size: 8px;
			content: "";
			position: absolute;
			top: 50%;
			left: 0;
			transform: translateY(-50%);
			border-left: @size solid #fff;
			border-top: @size solid transparent;
			border-bottom: @size solid transparent;
			opacity: 0;
			transition: opacity 0.3s;
		}

		&:hover, &:focus, &.active {
			color: #000;

			&:before {
				opacity: 1;
			}
		}

		&:nth-of-type(5n + 1) {
			background-color: #8f8ec2;

			+ div .category-links__child-link {
				background-color: #8f8ec2;
			}
		}

		&:nth-of-type(5n + 2) {
			background-color: #279dd3;

			+ div .category-links__child-link {
				background-color: #279dd3;
			}
		}

		&:nth-of-type(5n + 3) {
			background-color: #a8ce7d;

			+ div .category-links__child-link {
				background-color: #a8ce7d;
			}
		}

		&:nth-of-type(5n + 4) {
			background-color: #f4e845;

			+ div .category-links__child-link {
				background-color: #f4e845;
			}
		}

		&:nth-of-type(5n + 5) {
			background: #ea544d;

			+ div .category-links__child-link {
				background: #ea544d;
			}
		}
	}

	&__child-link {
		margin-left: 10px;
	}
}

.home-video-row {
	padding-bottom: 20px;

	&__video {
		padding-bottom: 56.25%;
		overflow: hidden;
		position: relative;

		iframe {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}

	&__social-feed {
		position: relative;
		overflow: hidden;
		padding-bottom: 118%;

		#FacebookEmbedPanel {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}
}

.page-listings {
	&__wrapper {
		background: #000;
		padding: 70px 0 60px;
	}

	&__title {
		color: #fff;
		margin-bottom: 50px !important;

		@media @mobile {
			font-size: 2.75rem;
		}

		.word {
			visibility: hidden;
		}

		&.animated {
			.word {
				visibility: visible;

				&:first-child {
					animation: fadeInLeft 1.2s 1 ease-out both;
				}

				&:last-child {
					animation: fadeInRight 1.2s 0.1s 1 ease-out both;
				}
			}
		}
	}

	& > div {
		@media (max-width:420px) {
			width: 100%;
		}
	}
}

.castlePanel {
	width: 100%;
	margin: 0 0 30px;
	display: flex;
	flex-direction: column;
	border: none;
	overflow: hidden;
	padding: 10px 10px;
	border-radius: 5px;
	box-shadow: 0 3px 5px rgba(0,0,0,0.3);
	transition: box-shadow 0.3s;

	&:hover {
		box-shadow: 0 2px 2px rgba(0,0,0,0.1);
	}

	.SingleImagePanel {
		height: auto;
		background-color: transparent;
		padding-top: 0;

		&:before {
			content: "";
			display: block;
			padding-top: 100%;
			width: 100%;
		}
	}

	.castleTitlePanel {
		padding: 0 5px;
		display: flex;
		margin-top: 10px;
		justify-content: center;
		align-items: center;

		a {
			font-size: 1rem;
			color: #444;
			.MontserratSemiBold;
		}
	}

	shortProductDescription {
	}

	.castlePriceDefault, .castlePriceDiscounted {
		padding: 0 5px;
		margin-top: 10px;
		font-size: 1.125rem;
		.OpenSansBold;
		color: #000;

		span.oldprice {
			font-size: 0.8rem;
		}

		span.newprice {
			font-size: 1rem;
		}
	}

	span.oldprice, span.newprice {
	}

	.castleCheckBook {
		height: auto;
		order: 4;
		padding: 10px 5px;
		margin-top: 10px;
		text-transform: uppercase;
		.MontserratBold;
		color: #fff;
		text-shadow: 0 1px 1px rgba(0,0,0,0.5);
		.Gradient(#de0588,#e82763);
		border-radius: 5px;
		font-size: 1rem;
	}
}

.DetailsTitle h1, .DetailsTitle h2, .DetailsMainImage {
	background-color: @castleDetailsColor;
	font-size: 2rem;
}

.DetailsTitle h1, .DetailsTitle h2 {
	padding: 10px;
	.MontserratMedium;
}

a.BackToCategory {
	@media @mobile {
		transform: translateY(30px);
		margin-bottom: 0;
	}

	&:hover {
	}
}

.clients {
	padding: 65px 0 95px;

	&__title {
		color: #000;
		position: relative;

		@media @mobile {
			font-size: 2.5rem;
		}

		&:after {
			content: "";
			background: #bfbfbf;
			width: 27px;
			height: 5px;
			position: absolute;
			bottom: -30px;
			left: 50%;
			transform: translateX(-50%);
		}
	}

	.slick-track {
		display: flex;
		align-items: center;
	}

	&__inner {
		margin-top: 60px;
	}

	&__image-panel {
		.FlexCenter;
		display: flex !important;
	}

	&__image {
	}
}

.testimonials {
	padding: 70px 0;
	.HorizontalGradient(#de0588,#ff7e00);
	position: relative;
	overflow: hidden;

	&__bg{
		position:absolute;
		top:0;
		right:0;
		bottom:0;
		left:0;
		width:100%;
		height:100%;
		object-fit:cover;
		opacity: 0.1;
		mix-blend-mode: multiply;
		animation-name: pulse;
		animation-play-state: running;
		animation-duration: 30s;
		animation-iteration-count: infinite;
	}

	&__inner {
		background: #fff;
		margin: 0 auto;
		max-width: 800px;
		padding: 50px 50px 40px;
		border-radius: 5px;
		position: relative;

		@media @mobile {
			padding: 25px;
		}
	}

	&__title {
		margin-bottom: 50px !important;

		@media @mobile {
			font-size: 2.5rem;
		}
	}

	.RotatorTestimonialContent {
		display: flex;
		flex-direction: column-reverse;
		text-align: center;

		p {
			margin-bottom: 0;
		}
	}

	time {
		display: none;
	}

	a[itemprop="author"] {
		.MontserratBold;
		font-size: 1.625rem;
		color: #d900b1;
		margin-bottom: 0;
		margin-top: 20px;
		display: block;
		position: relative;
		animation-delay: 0.45s;
		visibility: hidden;

		@media @mobile {
			font-size: 1.25rem;
		}
	}

	p[itemprop="reviewBody"] {
		font-size: 1.375rem;
		visibility: hidden;
		display: block;
		position: relative;

		@media @mobile {
			font-size: 1.125rem;
		}
	}

	.carousel-inner {
		.item {
			transition-property: opacity;
		}

		.item,
		.active.left,
		.active.right {
			opacity: 0;
		}

		.active,
		.next.left,
		.prev.right {
			opacity: 1;
		}

		.next,
		.prev,
		.active.left,
		.active.right {
			left: 0;
			transform: translate3d(0, 0, 0);
		}
	}

	.item.active {
		p[itemprop="reviewBody"], a[itemprop="author"] {
			animation-name: fadeInUp;
			animation-play-state: running;
			visibility: visible;
			animation-fill-mode: both;
			animation-iteration-count: 1;
			animation-direction: normal;
			animation-duration: 1s;
		}
	}

	#RotatorTestimonialsHeader {
		margin-bottom: 15px;

		p, a {
			text-align: center;
			color: #000;
			font-size: 1rem;
			position: relative;
		}
	}

	.RotatorTestimonialRating {
		max-width: 280px;
		margin: 20px auto 0;
	}
}

.newsletter {
	padding: 70px 0 50px;
	.HorizontalGradient(#de0588,#ff7e00);

	&__intro {
		color: #fff;
		text-align: center;
		margin-bottom: 30px;

		h2 {
			.MontserratBold;
			font-size: 3rem;
			margin-top: 0;
			text-transform: uppercase;

			@media @mobile {
				font-size: 2.25rem;
			}
		}

		p {
			.MontserratSemiBold;
			font-size: 1.75rem;
			margin-top: 10px;

			@media @mobile {
				font-size: 1.25rem;
			}
		}
	}

	label {
		display: none;
	}

	#NewsletterControl {
		display: flex;
		flex-direction: column;
		margin: 0 auto;
		justify-content: center;
		align-items: center;

		@media @md {
			flex-direction: row;
		}
	}

	&__name, &__email {
		height: 45px;
		border: none;
		border-radius: 5px;
		padding-left: 15px;
		.OpenSansSemiBold;
		font-size: 1rem;
		color: #000;
		margin-bottom: 15px;
		display: block;

		&::placeholder {
			color: #000;
			opacity: 1;
		}

		@media @md {
			margin-bottom: 0;
		}
	}

	&__name {
		width: 100%;
		max-width: 300px;

		@media @md {
			width: 300px;
			max-width: none;
		}
	}

	&__email {
		width: 100%;
		max-width: 300px;

		@media @md {
			width: 350px;
			max-width: none;
			margin: 0 10px;
		}
	}

	&__button {
		height: 45px;
		border: none;
		color: #fff;
		.MontserratBold;
		font-size: 1.2rem;
		text-transform: uppercase;
		.FlexCenter;
		position: relative;
		width: 160px;
		border-radius: 8px;
		border: 2px solid #078abc;
		transition: border 0.3s;
		z-index: 1;

		&:before {
			.StickToEdges;
			z-index: -1;
			border-radius: 5px;
			.Gradient(#2db0e2,#078abc);
			transition: opacity 0.3s;
		}

		&:hover {
			color: #fff;
			border: 2px solid #fff;
			text-shadow: 1px 1px 1px rgba(0,0,0,0.25);

			&:before {
				opacity: 0;
			}
		}

		@media @md {
		}
	}
}

.home-gallery {
	background: #fff;
	display: block;
	position: relative;
	padding: 20px 0 15px;
	background: transparent;
	@color: #000;

	&:before, &:after {
		content: "";
		position: absolute;
		width: 150px;
		top: 0;
		bottom: 0;

		@media @mobile {
			width: 50px;
		}
	}

	&:before {
		left: 0;
		z-index: 1;
		.HorizontalGradient(fade(@color,100),fade(@color,0));
	}

	&:after {
		right: 0;
		mask-image: linear-gradient(-90deg,rgba(0,0,0,1) 20%, rgba(0,0,0,0) 100%);
		background-position-x: ~'calc(((100vw - 1200px)/2) - 100vw + 3px)';
		.HorizontalGradient(fade(@color,0),fade(@color,100));
	}

	&__wrapper {
		background-color: @color;
	}

	&__container {
		background: #fff;
	}

	&__slide {
		margin: 0 10px;
	}

	&__link {
		display: block;
		border-radius: 5px;
		overflow: hidden;
		padding: 0;
		margin: 0 10px;
		border: 5px solid #fff !important;
		border-radius: 0 !important;
		background-color: #fff;
		//transition: all 0.3s;
		.BoxShadow1;
		padding: 1% 1% 63% !important;

		&:hover {
			border-color: #ababab;
		}

		img {
			left: 0 !important;
			top: 0 !important;
		}
	}
}

.DetailsLeft {
	background-image: none;
	padding-bottom: 0;
}

.BookButtons {
	text-align: center;

	.BookButton, .CheckButton {
		width: 180px;
		margin-left: auto;
		float: none;
		margin: 0 10px 10px;
	}
}

.footer {
	background: #000;
	padding: 30px 0;
	color: #fff;

	@media @mobile {
		text-align: center;
	}

	a, p {
		color: #fff;

		&:hover {
			color: #fff;
		}
	}

	&__col {
		@media @mobile {
			text-align: center;
		}
	}

	&__flex-row-md {
		@media @md {
			display: flex;
			align-items: center;
		}
	}

	&__text-right-sm {
		@media @sm {
			text-align: right;
		}
	}

	&__links {
		text-align: center;
	}

	&__link {
		margin: 0 5px;
	}

	#BCNLink {
		display: inline-block;
		margin-top: 15px;

		@media @sm {
			margin-top: 0;
		}
	}
}
/*#region Animations*/
.wow {
	visibility: hidden;
}

.animated {
	animation-duration: 1s;
	animation-fill-mode: both
}

@keyframes fadeIn {
	0% {
		opacity: 0
	}

	to {
		opacity: 1
	}
}

.fadeIn {
	animation-name: fadeIn
}

.fadeInUp2 {
	animation-name: fadeInUp2;
	animation-iteration-count: infinite;
	animation-direction: alternate;
	animation-duration: 2s;
}

@keyframes fadeInUp2 {
	0% {
		opacity: 0;
		transform: translate3d(0, 100px,0);
	}

	100% {
		opacity: 1;
	}
}
/* Recommended styles for Splitting */
.splitting .word,
.splitting .char {
	display: inline-block;
}
/* Psuedo-element chars */
.splitting .char {
	position: relative;
}
/**
 * Populate the psuedo elements with the character to allow for expanded effects
 * Set to `display: none` by default; just add `display: block` when you want
 * to use the psuedo elements
 */
.splitting .char::before,
.splitting .char::after {
	content: attr(data-char);
	position: absolute;
	top: 0;
	left: 0;
	visibility: hidden;
	transition: inherit;
	user-select: none;
}
/* Expanded CSS Variables */
.splitting {
	/* The center word index */
	--word-center: calc((var(--word-total) - 1) / 2);
	/* The center character index */
	--char-center: calc((var(--char-total) - 1) / 2);
	/* The center character index */
	--line-center: calc((var(--line-total) - 1) / 2);
}

.splitting .word {
	/* Pecent (0-1) of the word's position */
	--word-percent: calc(var(--word-index) / var(--word-total));
	/* Pecent (0-1) of the line's position */
	--line-percent: calc(var(--line-index) / var(--line-total));
}

.splitting .char {
	/* Percent (0-1) of the char's position */
	--char-percent: calc(var(--char-index) / var(--char-total));
	/* Offset from center, positive & negative */
	--char-offset: calc(var(--char-index) - var(--char-center));
	/* Absolute distance from center, only positive */
	--distance: calc( (var(--char-offset) * var(--char-offset)) / var(--char-center) );
	/* Distance from center where -1 is the far left, 0 is center, 1 is far right */
	--distance-sine: calc(var(--char-offset) / var(--char-center));
	/* Distance from center where 1 is far left/far right, 0 is center */
	--distance-percent: calc((var(--distance) / var(--char-center)));
}
/*#endregion Animations*/

@keyframes pulse {
	0% {
		transform: scaleX(1)
	}

	50% {
		transform: scale3d(1.05,1.05,1.05)
	}

	to {
		transform: scaleX(1)
	}
}

.pulse {
	animation-name: pulse
}
